.App {
  font-family: 'Arial', sans-serif;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
  color: #333; /* Dark grey for text */
}

article {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

article h1 {
  color: #cc0099; /* A feminine touch with pinkish color */
  margin-bottom: 0.5em;
}

article div {
  margin-bottom: 1em;
  font-size: 1.1em; /* Slightly larger for headlines */
  color: #666; /* Lighter grey for headlines */
}

.StructuredText {
  line-height: 1.6;
  margin-bottom: 2em;
}

.StructuredText > *:first-child {
  margin-top: 0;
}

.StructuredText > *:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block; /* Ensures the image is treated as a block-level element */
  margin: 0 auto; /* Centers the image horizontally */
  border-radius: 4px;
}

.article-image {
  display: block;
  margin: 0 auto; /* Centers the image */
}


/* Specific styles for Image components */
.Image {
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  article {
    padding: 15px;
  }
}
